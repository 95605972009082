/**
 * Fire a click event on click outside the element, to close modals, navigation etc.
 */
export default defineNuxtPlugin((nuxtApp) => {
	nuxtApp.vueApp.directive('click-outside', {
		created(element, binding) {
			element.clickOutsideEvent = function (event) {
				if (!(element === event.target || element.contains(event.target))) {
					binding.value(event, element);
				}
			};
			document.body.addEventListener('click', element.clickOutsideEvent);
		},
		unmounted(element) {
			document.body.removeEventListener('click', element.clickOutsideEvent);
		},
	});
});
